@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.integrated {

    .swiper {
        width: 100%;
        
        padding: 0 !important;
        overflow: hidden !important;
    
        height: 57vh !important;
        
        @media screen and (max-width: 950px) {
            height: 400px !important;
        }
    
        @media screen and (max-width: 768px) {
            height: 300px !important;
        }
    
        @media screen and (max-width: 514px) {
            height: 250px !important;
        }
    
        .swiper-wrapper {
            height: 100%;
        }
    
    
        .swiper-button-prev, .swiper-button-next {
            color: $button-color !important;
            border-radius: 100%;
            padding: 10px;
            background-color: $secondary-color;
            padding: 45px;
        }
    
        .swiper-button-next {
            right: 25px !important;

        }
    
        .swiper-button-prev {
            left: 25px !important;
        }
    
    
    }
    
    .image {
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
    
        // @media screen and (max-width: 950px) {
        //     height: 400px;
        // }
    
        // @media screen and (max-width: 768px) {
        //     height: 300px;
        // }
    
        // @media screen and (max-width: 514px) {
        //     height: 250px;
        // }
    }

    

    .block {
        width: 100%;
        background: rgba(69, 154, 87, 0.50);


        z-index: 1;


    }

    .content {
        position: relative;
        z-index: 2;

        .top {

            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                @media screen and (max-width: 950px) {
                    text-align: center;
                }
            }


            .title {
                color: $button-color !important;
                margin-top: 20px;
                margin-bottom: 30px;
            }

            .desc {
                width: 70%;
                margin-top: 10px;
                margin-bottom: 53px;
                text-align: center;
            }
        }

        .cardRow {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 33px;
            width: 100%;
            padding: 50px 0;

            .kaartje {
                padding: 39px 30px 29px 30px;
                min-width: 398px;

                border-radius: 18px;
                border: 1px solid rgba(255, 255, 255, 0.05);
                background: rgba(13, 13, 43, 0.20);


                @media screen and (max-width: 1028px) {
                    min-width: fit-content;
                    width: 100%;
                }


                .title {
                    color: $button-color;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                }

                .basicText {}
            }
        }


    }

}


