@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.circular {
    padding: 150px 0;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 30px;

        .basicText {
            text-align: center;
        }

        .logo {
            background-image: url('./images/icon.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 96px;
            height: 112px;
            flex-shrink: 0;
        }

        .rowCircles {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            .andere {
                display: flex;
                row-gap: 0px;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                p {
                    margin-top: 10px;
                    font-size: 14px;
                    color: $button-color;
                    width: 100%;
                    text-align: center;
                }

                .rond {
                    width: 176px;
                    height: 176px;
                    flex-shrink: 0;
                    background-color: $secondary-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;

                    .midden {
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                }

               
            }
        }



        .spinning {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            height: 750px;
            align-items: center;

            .object {
                position: absolute;
                width: 176px;
                height: 176px;
                flex-shrink: 0;
                background-color: $secondary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                animation: spin 60s linear infinite; // Increased duration to 60 seconds
                text-align: center;

                .center {
                    background-position: center center;
                    width: 70px;
                    height: 70px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                .label {
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: 10px;
                    font-size: 14px;
                    color: $button-color;
                    width: 200px;
                }
            }

            .object.first {
                animation-delay: 0s;
            }

            .object.second {
                animation-delay: -15s; // Quarter of the animation duration
            }

            .object.third {
                animation-delay: -30s; // Half of the animation duration
            }

            .object.fourth {
                animation-delay: -45s; // Three-quarters of the animation duration
            }

            
        }
        .img {
            background-image: url('./images/pallet.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 473px;
            height: 300px;
            z-index: 10000;

            @media screen and (max-width: 768px) {
                width: 100%;
                height: 234px;
                
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg) translateX(250px) rotate(0deg);
    }

    100% {
        transform: rotate(360deg) translateX(250px) rotate(-360deg);
    }
}