@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.distribution {
    background-color: $secondary-color;
    padding: 150px 0;
    position: relative;

    @media screen and (max-width: 1350px) {
        padding: 0px 0 50px 0;
    }

    @media screen and (max-width: 514px) {}

    .left {
        flex: 1;
        width: 100%;
        height: 450px;
        background-image: url('./images/distributionBG.png');
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;

        @media screen and (max-width: 950px) {
            flex: auto;
            position: relative;
            height: 324px;
            width: 100%;
            background-position: center center;
        }
    }

    .content {
        gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width: 950px) {
            flex-direction: column;
            align-items: center;
        }

        .right {
            
            flex: 1;

            @media screen and (max-width: 950px) {
                
                text-align: center;
            }

            .basicText {
                text-align: justify;

                @media screen and (max-width: 950px) {
                    text-align: center;
                    margin-top: 10px;
                }


            }

        }
    }
}