@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.sliderSection {
   background-color: $secondary-color;
   padding-bottom: 60px;


   .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h3 {
         margin-top: 40px;

         @media screen and (max-width:768px) {
            margin-top: 20px;
         }
         
      }

      .serviceRow {
         margin-top: 20px;
         margin-bottom: 20px;
         display: flex;
         justify-content: space-between;
         align-items: center;

         @media screen and (max-width:950px) {
            flex-direction: column;
         }

         .serviceAll {
            flex: 1;
            display: flex;
            row-gap: 15px;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width:950px) {
               row-gap: 8px;
            }

            .serviceCircle {
               width: 176px;
               height: 176px;
               flex-shrink: 0;
               background-color: $primary-color;
               border-radius: 100%;
               display: flex;
               align-items: center;
               justify-content: center;

            }

            .title {
               margin-top: 0;
               margin-bottom: 0;
               font-size: 18px;
               text-transform: uppercase;
            }

            .desc {
               min-height: 70px;
               margin-top: 0;
            }

         }
      }



      .title {
         color: $button-color !important;
         margin-top: 50px;
         margin-bottom: 30px;

         @media screen and (max-width: 950px) {
            margin-top: 25px;
            margin-bottom: 10px;
         }
      }

      .desc {
         width: 70%;
         margin-top: 10px;
         margin-bottom: 10px;

         @media screen and (max-width: 950px) {
            width: 100%;
            margin-bottom: 0px;
         }
      }

      .image {
         background-size: contain;
         background-repeat: no-repeat;
         background-position: center center;
         width: 100%;
         height: 554px;


         @media screen and (max-width: 950px) {
            height: 450px;
         }
      }

      .image.manu {
         height: 450px;

         @media screen and (max-width: 950px) {
            height: 300px;
         }

         @media screen and (max-width: 768px) {
            height: 280px;
         }

         @media screen and (max-width: 514px) {
            height: 220px;
         }
      }
   }
}