@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.supplyChain {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 86vh;
    position: relative;
    background-image: url('./images/image\ 13.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;


    @media screen and (max-width: 950px) {
        padding: 90px 0 100px 0;
        height: fit-content;
    }

    @media screen and (max-width: 514px) {
        padding: 45px 0 0px 0;
    }

    h3 {
        color: $primary-color;
        width: 50%;
        margin-bottom: 40px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .desc {
        width: 50%;
        color: $primary-color !important;
        text-align: justify;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    a {
        margin-top: 20px;
        width: fit-content;
    }

}