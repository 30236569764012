@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.navbar {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%);
    position: relative;

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 21px 160px 31px 160px;

        @media screen and (max-width: 1770px) {
            padding: 21px 100px 31px 100px;
        }

        @media screen and (max-width: 768px) {
            padding: 21px 30px 21px 30px;
        }

        .logo {
            width: 220px;
            height: 80px;
            background-image: url('./images/logo.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;

            

            @media screen and (max-width: 1300px) {
                width: 150px;
                height: 50px;
            }

            @media screen and (max-width: 514px) {
                width: 120px;
                height: 50px;
            }

        }

        &__right {
            display: flex;
            column-gap: 32px;

            .content {
                display: flex;
                align-items: center;
                column-gap: 42px;



                @media screen and (max-width: 1300px) {
                    column-gap: 30px;
                }

               

                .titles {
                    display: flex;
                    column-gap: 42px;

                    @media screen and (max-width: 1300px) {
                        column-gap: 30px;
                    }

                    



                    a {
                        color: $text-color;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                    }

                    a.active {
                        height: auto;
                        color: $button-color;

                    }
                }

            }

            .rect {
                height: 24px;
                width: 1px;
                background-color: #F2F2F2;
            }

            .hamburger-btn {
                background: none;
                border: none;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 30px;
                height: 24px;
                padding: 0;
                margin: 0;
            }

            .bar {
                width: 100%;
                height: 4px;
                background-color: $button-color;
                transition: transform 0.3s ease, opacity 0.3s ease;
                opacity: 0.4;
            }

            .bar.open {
                opacity: 1;
                transform: rotate(-45deg) translate(-10px, 9px);
            }

            .bar.open:nth-child(2) {
                opacity: 0;
            }

            .bar.open:nth-child(3) {
                transform: rotate(45deg) translate(-4px, -4px);
            }
        }
    }



}

.menu {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 21px 160px 31px 160px;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    z-index: 1000000;
    position: relative;
    background-color: $primary-color;
    align-items: center;

    @media screen and (max-width: 1200px) {

        padding: 31px 100px 31px 100px;
    }

    @media screen and (max-width: 768px) {
        padding: 60px 30px 21px 30px;

    }

    a {
        text-align: center;
        color: $text-color;
        font-size: 20px;
    }

    a.active {
        height: auto;
        font-weight: 700;
        color: $button-color;
    }

    .button {
        width: fit-content;
    }

}

.logo {
    display: flex;
    align-items: center;

    i.icon svg path {
        fill: rgb(228, 3, 44);
    }
}

.nav-link {
    color: var(--ORTELIUS-WHITE, #F3F3F3);
}



.nav-link-pricing {
    color: var(--Soft-Black, #323232);

}