@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.incorperating {
    padding: 325px 0 101px 0;

    @media screen and (max-width: 1444px) {
        padding: 260px 0 90px 0;
      }
    
      @media screen and (max-width: 909px) {
        padding: 180px 0 75px 0;
      }
    
      @media screen and (max-width: 768px) {
        padding: 120px 0 60px 0;
      }
    
      @media screen and (max-width: 514px) {
    
        padding: 80px 0 40px 0;
      }

    background:
        linear-gradient(180deg, rgba(14, 14, 43, 0.00) 0%, #459A57 63.5%),
        url('./images/incopng.png') no-repeat top/cover;

    .content {


        .text {
            margin-bottom: 90px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @media screen and (max-width:950px) {
                width: 100%;
                flex-direction: column;
                justify-content: center;
                row-gap: 20px;
                align-items: center;
            }

            .left {
                flex: 1;

                .title {
                    color: $button-color !important;
                    margin-top: 20px;

                }
            }

            .right {
                flex: 1;
                
                display: flex;
                flex-direction: column;

                row-gap: 15px;

                @media screen and (max-width:950px) {
                    width: 100%;
                }

                .phrase {
                    display: flex;
                    column-gap: 25px;
                    align-items: center;

                    @media screen and (max-width:514px) {
                        column-gap: 10px;
                    }

                    i.icon svg path {
                        fill: none;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 500;
                        color: $white !important;

                        @media screen and (max-width:950px) {
                            font-size: 14px;
                        }

                        @media screen and (max-width:514px) {
                            font-size: 12px;
                        }
                    }

                }
            }
        }





    }

}