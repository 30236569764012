@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.dashboard {
    background-color: $secondary-color;
    
    width: 100%;
    background-size: cover;
    padding: 77px 0 350px 0;

    @media screen and (max-width: 950px) {
        padding: 77px 0 250px;
    }

    @media screen and (max-width: 768px) {
        padding: 77px 0 150px;
    }

    
    
    

    .imgBottom {
        background-image: url('./images/bottomDashboard.png');
        width: 100%;
        height: 788px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom:-730px;
        

        @media screen and (max-width: 950px) {
            bottom: -680px;
        }
    
        @media screen and (max-width: 768px) {
            bottom: -620px;
        }

        @media screen and (max-width: 514px) {
            bottom: -530px;
        }

        @media screen and (max-width: 420px) {
            bottom: -500px;

        }

    }

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        row-gap: 20px;
        position: relative;


        .title {
            color: $button-color;
            font-size: 20px;
        }

        .basicText {
            color: $grey;
        }

        .middleSection {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            margin-top: 68px;
            margin-bottom: 75px;

            @media screen and (max-width: 950px) {
                flex-direction: column;
                row-gap: 20px;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            .left,
            .right {
                display: flex;
                flex-direction: column;
                row-gap: 15px;

                .phrase {
                    display: flex;
                    column-gap: 25px;
                    align-items: center;

                    @media screen and (max-width: 768px) {
                        column-gap: 10px;
                    }

                    i.icon svg path {
                        fill: none;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 500;

                        @media screen and (max-width: 768px) {
                            font-size: 12px;
                            text-align: left;
                        }
                    }
                }
            }

            .left {}

            .right {}
        }

        




    }
}