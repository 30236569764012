.header {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 82px 0;
    background: url('./images/home.png') lightgray 50% / cover no-repeat;
    background-position: center center;

    @media screen and (max-width: 1350px) {
        padding: 120px 0;
    }

    @media screen and (max-width: 1024px) {
        padding: 100px 0;
    }

    @media screen and (max-width: 768px) {
        display: block;
        padding: 150px 0;
    }

    .margins {
        a {
            width: fit-content;
        }
    }

}