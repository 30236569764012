@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.headerSer {
    background-image: url('./images/services.png');
    height: 100vh;
    padding: 82px 0;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    

    @media screen and (max-width: 1350px) {
        padding: 120px 0;
    }

    @media screen and (max-width: 1024px) {
        padding: 100px 0;
    }

    @media screen and (max-width: 768px) {
        display: block;
        padding: 150px 0;
    }

    @media screen and (max-width: 514px) {
        
    }

   

    .content {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        position: relative;

        a {
            width: fit-content;
        }

        @media screen and (max-width: 1444px) {
            row-gap: 25px;
          }
        
          @media screen and (max-width: 909px) {
            row-gap: 20px;
          }
        
          @media screen and (max-width: 768px) {
            row-gap: 15px;
          }
        
          @media screen and (max-width: 514px) {
            row-gap: 10px;
          }

        button {
            width: fit-content;
            z-index: 1000;
        }

        p {
            z-index: 1000;
        }
    }
}