@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.banner {



    .content {
       display: flex;
       justify-content: center;

        .inside {
            width: 100%;
           
            border-radius: 18px;
            border: 1px rgba(255, 255, 255, 0.05);
            background: rgba(255, 255, 255, 0.30);
            backdrop-filter: blur(47px);
            display: flex;
            padding: 48px;
            justify-content: space-between;
            align-items: center;

            
        }
    }
}