i.icon {
  display: inline-block;
  margin: 0;
  svg {
    overflow: visible;
    path {
      
    }
  }
}
