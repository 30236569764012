@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.environment {
    padding: 30px 0 100px 0;
    position: relative;
    margin-top: 40px;

    @media screen and (max-width: 1444px) {
        padding: 40px 0 80px 0;
      }
    
      @media screen and (max-width: 909px) {
        padding: 40px 0 50px 0;
      }
    
      @media screen and (max-width: 768px) {
        padding: 40px 0 10px 0;
      }
    
      @media screen and (max-width: 514px) {
    
        padding: 40px 0 50px 0;
      }
    

    .circle {
        position: absolute;
        border-radius: 100%;
        background: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0.026) 0deg, rgba(255, 255, 255, 0.012) 360deg);
        width: 500px;
        height: 500px;
        top: -300px;
        right: -300px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;


        .title {
            color: $button-color !important;
            margin-top: 20px;
            margin-bottom: 0px;
        }

        h2 {


            @media screen and (max-width: 950px) {
                text-align: center;
            }
        }

        .doos {
            margin-top: 40px;
            width: 100%;
            height: 581px;
            background-image: url('./images/envipng.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            
            @media screen and (max-width: 950px) {
                background-image: url('./images/envimobilepng.png');
                height: 1000px;
            }

            

            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 950px) {
                align-items: flex-start;
            }
        }


    }

}