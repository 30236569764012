@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.pallet {
    position: relative;
    padding: 140px 0 0;

    @media screen and (max-width:950px) {
        padding: 0;
    }

    .img {
        top: 0;
        position: absolute;
        background-image: url('./images/pallet.png');
        width: 471px;
        height: 289.915px;

        flex-shrink: 0;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

        @media screen and (max-width:950px) {
            position: relative;
            width: 100%;
        }

        @media screen and (max-width:768px) {
            height: 350px;
        }

        @media screen and (max-width:514px) {
            height: 250px;
        }
    }


    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            margin-bottom: 20px;
            margin-top: 20px;
        }



        .desc {
            display: flex;
            justify-content: space-between;
            margin-bottom: 60px;
            gap: 40px;

            @media screen and (max-width:950px) {
                flex-direction: column;
                gap: 20px;
            }

            .part {
                flex: 1;
                text-align: justify;
                font-size: 16px;
                font-weight: 400;
                line-height: 175%;

                @media screen and (max-width:768px) {
                    text-align: justify;
                }
            }
        }

        .cardRow {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            justify-content: center;

            .kaartje {
                width: 582px;
                display: flex;
                padding: 20px 50px;
                align-items: center;
                column-gap: 50px;

                min-height: 200px;
                border-radius: 12px;
                border: 1px solid rgba(255, 255, 255, 0.08);
                background: rgba(255, 255, 255, 0.05);
                box-shadow: 0 4px 4px 0 #00000010;

                @media screen and (max-width: 1378px) {
                    width: 500px;
                    display: flex;
                    padding: 20px 30px 20px 30px;
                }

                @media screen and (max-width: 950px) {
                    width: 100%;
                    padding: 20px;
                    column-gap: 30px;
                }

                i.icon svg path {
                    fill: none;
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    row-gap: 15px;

                    @media screen and (max-width: 768px) {
                        row-gap: 10px;
                    }

                    .top {
                        color: #00FA54;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 32px;

                        @media screen and (max-width: 950px) {
                            font-size: 20px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                        }
                        
                        
                    }

                    .basicText {
                        line-height: 160%;
                        min-height: 134px;
                        
                        @media screen and (max-width:1378px) {
                            min-height: min-content;
                        }
                    }
                }
            }
        }

        .bottomSection {}

    }

}