@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';


.box {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 48px;
    background-color: $button-color;
    border-radius: 16px;
    color: $primary-color;
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 100%;

    @media screen and (max-width: 950px) {
        
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
    }

    @media screen and (max-width: 514px) {

    }

    .left {
        z-index: 1000;
        flex: 1;

        .basicText {
            margin-top: 20px;
            width: 100%;
            color: $primary-color !important;

        }
    }

    .right {
        z-index: 1000;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 40px;
        flex: 1;

        @media screen and (max-width: 950px) {
            column-gap: 20px;
        }

        @media screen and (max-width: 768px) {
            justify-content: left;
            width: 100%;
        }

        @media screen and (max-width: 514px) {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 20px;
        }


        input {
            background-color: transparent;
            border: none;
            color: $primary-color;
            padding: 10px;
            width: 50%;
            border-bottom: 1px solid $primary-color;
            font-size: 16px;
            border-radius: 0;

            @media screen and (max-width: 950px) {
                padding: 8px;
            }

            @media screen and (max-width: 768px) {
                padding: 6px;
            }

            @media screen and (max-width: 514px) {
                padding: 4px;
                width: 100%;
            }
        }

        input:focus {
            outline: none
        }

        input::placeholder {
            color: $primary-color;
            font-size: 16px;
            opacity: 0.9;

            @media screen and (max-width: 950px) {
                font-size: 14px;
            }

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }

           
        }

        .button {
            background-color: #014917;

            p {
                color: $button-color;

            }
        }
    }


}