@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.practices {

    padding: 100px 0;

    @media screen and (max-width: 950px) {
        padding: 90px 0 100px 0;
    }

    @media screen and (max-width: 514px) {
        padding: 45px 0 0px 0;
    }
    
    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        

        h3 {
            text-align: center;
            width: 70%;
        }

        .middle {
            display: flex;
            column-gap: 50px;
            margin-top: 50px;
            position: relative;

            .left {
                z-index: 50;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                width: 50%;

                border-radius: 24px;
                background: rgba(1, 73, 23, 0.70);
                box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;                
                
                
                backdrop-filter: blur(10px);
                padding: 32px;

                @media screen and (max-width: 950px) {
                   width: 80%;
                }

                @media screen and (max-width: 768px) {
                        width: 100%;
                }
            
                @media screen and (max-width: 514px) {
                    
                }

                a {
                    width: fit-content;
                }

                .basicText {
                    text-align: justify;
                }
            }

            .right {
                background-image: url('./images/tijdelijk.png');
                position: absolute;
                height: 100%;
                width: 100%;
                background-position: right;
                background-size: contain;
                background-repeat: no-repeat;
                right: -100px;
                top: 150px;
                z-index: 2;

                @media screen and (max-width: 514px) {
                    top:60px;
                    right: -80px;
                    width: 120%

                }
            }
        }
    }
}