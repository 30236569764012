@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.swiperT {
    padding: 100px 0;

    .content {

        .tableCard {
            text-align: center;
            padding: 14px 24px 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: $secondary-color;
            border-radius: 25px;
            width: 340px;

            i.icon svg path {
                fill: #00FA54;
            }

            h4 {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .basicText {
                min-height: 100px;
                line-height: 150%;

                @media screen and (max-width: 950px) {
                    min-height: 80px;
                }

                @media screen and (max-width: 514px) {
                    min-height: 150px;
                }
            }

        }

        .swiper {
            width: 100%;
            height: 100%;
            overflow: visible;
            padding: 70px;

            @media screen and (max-width: 768px) {
                padding: 35px;
            }

            @media screen and (max-width: 515px) {
                padding: 15px;
            }

            .swiper-button-prev, .swiper-button-next {
                color: $button-color !important;
                background-color: transparent;
                position: absolute;
                top: -180px;
                z-index: 10000000000000;

                @media screen and (max-width:768px) {
                    visibility: hidden;
                }
            }
        
            .swiper-button-next {
                
                
            }
        
            .swiper-button-prev {
               
               
            }

            .swiper-slide {
                transition: all .3s ease;
                width: 90%;
                opacity: 0.3;


                &.swiper-slide-active {
                    transform: scale(1.10);
                    z-index: 10000;
                    opacity: 1;
                }
            }

            .swiper-slide, .swiper-slide-next, .swiper-slide-previous, .swiper-slide-active {
                width: 500px;
            }
        }
    }
}