@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.consumer {
    
    background-image: url('./images/consumerBG.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 180vh;

    @media screen and (max-width:950px) {
       
        height: 150vh;
    }

    @media screen and (max-width:768px) {
       
        height: fit-content;
    }

    .content {
        width: 100%;


        .nieuweBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 20px;
            background-color: $white;
            padding: 83px 80px 48px 80px;

            @media screen and (max-width:1050px) {
               padding: 40px 60px;
            }

            @media screen and (max-width: 768px) {
                padding: 20px 30px;
            }


            h2 {

                color: $primary-color;
                line-height: 107%;
                
                margin-bottom: 20px;
    
                @media screen and (max-width:950px) {
                    width: 100%;
                }
            }
    
            .chain {
                background: rgba(13, 13, 43, 0.60) !important;
                margin-bottom: 20px !important;
            }
    
    
            .block {
                display: flex;
                justify-content: space-between;
                column-gap: 30px;
    
                @media screen and (max-width:1050px) {
                    flex-direction: column;
                    row-gap: 20px;
                }
    
    
                .left {
                    flex: 1;
                    color: $primary-color;
                    font-size: 16px;
                    text-align: justify;

                    @media screen and (max-width:768px) {
                        text-align: left;
                        
                    }
        
                }
    
                .list {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    row-gap: 15px;
    
                    .phrase {
                        display: flex;
                        column-gap: 25px;
                        align-items: center;
    
                        @media screen and (max-width:950px) {
                            columns: 20px;
                        }
    
                        @media screen and (max-width:768px) {
                            column-gap: 15px;
                        }
    
                        @media screen and (max-width:514px) {
                            column-gap: 10px;
                        }
    
                        i.icon svg path {
                            fill: none;
                        }
    
                        p {
                            font-size: 16px;
                            font-weight: 500;
                            color: $primary-color;
    
                            @media screen and (max-width:950px) {
                                font-size: 14px;
                            }
    
                            @media screen and (max-width:768px) {
                                font-size: 14px;
                            }
    
                            @media screen and (max-width:514px) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }


        }


        


    }
}