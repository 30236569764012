@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.adress {
    background-color: $white;
    padding: 60px 0;
    position: relative;

    .circleLeft {
        position: absolute;
        left: 0;
        bottom: calc(50% - 500px);
        background-image: url('./images/bgleft.png');
        width: 700px;
        height: 700px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left;


        @media screen and (max-width:950px) {
            width: 500px;
            height: 500px;
            bottom: calc(50% - 450px);
        }

        @media screen and (max-width:514px) {
            width: 300px;
            height: 300px;
            bottom: calc(50% - 380px);
        }


    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
        color: $primary-color;

        .basicText {
            color: $primary-color !important;
            text-align: center;
        }

        .title {
            width: 70%;
        }

        .bald {
            font-weight: 600;
        }

        .logo {
            background-image: url('./images/logoBlue.png');
            background-position: center center;
            background-size: contain;
            width: 60px;
            height: 68.771px;
            background-repeat: no-repeat;
        }

        .other {
            display: flex;
            flex-direction: column;
            align-items: center;

            font-size: 16px;
        }
    }


}