@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.clarity {
    padding: 40px 0;
    background-color: $white;

    .content {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 950px) {
            flex-direction: column;
            row-gap: 40px;
        }

        .left {
            flex: 1;

            .chain {
                margin-bottom: 20px;
            }

            h2 {
                font-size: 40px;
                color: $primary-color;
                margin-bottom: 20px;
            }

            .basicText {
                color: $primary-color;
                text-align: justify;
            }
        }

        .right {
            flex: 1;
            height: 502px;
            background-image: url('./images/clarityPng.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;

            @media screen and (max-width: 950px) {
                flex: auto;
            }

            @media screen and (max-width: 768px) {
                height: 350px;
            }

            @media screen and (max-width: 514px) {
                height: 273px;
            }
        }
    }
}