@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.shipped {
    padding: 390px 0 80px;
    position: relative;
    
    @media screen and (max-width: 950px) {
        padding: 350px 0 80px;
    }

    @media screen and (max-width: 768px) {
        padding: 340px 0 80px;

    }

    @media screen and (max-width: 514px) {
        padding: 200px 0 0;
    }

    @media screen and (max-width: 420px) {
      padding: 120px 0 0;  
    }
   

    .content {
       display: flex;
       flex-direction: column;
       align-items: center;
       text-align: center;

        .top {
            color: $button-color;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }

        h2 {
            font-size: 40px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .basicText {
            width: 70%;

            @media screen and (max-width: 950px) {
                width: 100%;
            }
        }

        .img {
            margin-top: -100px;
            width: 100%;
            height: 536px;
            background-image: url('./images/imgShipped.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            @media screen and (max-width: 950px) {
                margin-top: -75px;
                height: 350px;
            }
        
           
        
            @media screen and (max-width: 514px) {
                height: 283px;
            }
        
            @media screen and (max-width: 420px) {
                height: 250px;
            }
        }
    }
}