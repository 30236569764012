/**
* The breakpoints defined here should be changed per project.
* It also depends whether you design mobile-first or dektop-first.
* Mobile first: use 'min-width' in the media queries
* Desktop first: use 'max-width' in the media queries
*/

$breakpoint-big-monitor: 1640px;
$breakpoint-monitor: 1350px;
$breakpoint-desktop: 1025px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 414px;


@mixin breakpoint($point) {
  @if $point == phone {
    @media (max-width: $breakpoint-phone) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: $breakpoint-tablet) {
      @content;
    }
  } @else if $point == desktop {
    @media (max-width: $breakpoint-desktop) {
      @content;
    }
  } @else if $point == monitor {
    @media (max-width: $breakpoint-monitor) {
      @content;
    }
  } @else if $point == big-monitor {
    @media (max-width: $breakpoint-big-monitor) {
      @content;
    }
  }
}
