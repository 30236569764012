@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.final {
    padding: 62px 0;

    @media screen and (max-width: 950px) {
        padding: 30px 0;
    }

    .content {
        display: flex;
        column-gap: 80px;

        @media screen and (max-width: 950px) {
            flex-direction: column;
            text-align: center;
            row-gap: 40px;
        }

        .leftF,
        .rightF {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            @media screen and (max-width: 950px) {
               align-items: center;
               row-gap: 10px;
            }

            .title {
                color: $button-color;
            }

            .block {
                min-height: 144px;

                @media screen and (max-width: 950px) {
                    min-height: 0px;
                }
            }

            .logo {
                background-image: url('./images/icon.png');
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 38px;
                height: 47px;
            }


            .location {
                display: flex;
                flex-direction: column;
            }
        }


    }


}