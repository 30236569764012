@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.startNow {

    padding: 160px 0 88px 0;

    @media screen and (max-width: 950px) {
        padding: 90px 0 100px 0;
    }

    @media screen and (max-width: 514px) {
        padding: 45px 0 0px 0;
    }


    .stripe1,
    .stripe2 {
        border-radius: 130px;
        position: absolute;
        transform: rotate(-120deg);
        z-index: 1;
        width: 120px;
        height: 1002.259px;
    }

    .stripe1 {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.49%, #00F956 39.06%, rgba(255, 255, 255, 0.00) 99.92%);
        top: -450px;
        left: 200px;
    }

    .stripe2 {
        background: linear-gradient(180deg, #00F956 34.75%, rgba(0, 249, 86, 0.01) 100%);
        top: -500px;
        right: 250px;

        @media screen and (max-width: 1200px) {
            right: 150px;
        }
    
        @media screen and (max-width: 1050px) {
            right: 50px;
        }

        @media screen and (max-width: 950px) {
            visibility: hidden;
        }
    }

    .content {
        z-index: 1000;
    }





}