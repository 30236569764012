$primary-color: #014917;
$secondary-color:  #459A57;
$button-color: #00FA54;
$text-color: #FFFFFF;
$text-color-dark:#0D0D2B;
$placeholder-color: #b8bcbf;
$disabled-background-color: #f3f3f3;
$error-background-color: #fff6f6;
$error-border-color: #e0b4b4;
$error-text-color: #9f3a38;
$error-placeholder-color: #e7bdbc;
$input-border-color: rgba(34, 36, 38, 0.25);

$shadow: rgba(34, 36, 38, 0.15);
$white-transparent: rgba(255, 255, 255, 0.2);

$grey: #E0E0E0;
$grey-dark: #666666;
$grey-light: #ebeced;
$grey-lighter: #f9fafb;
$red: #e45f55;
$fill-white: #FFFFFF;
$red-light: #fceaea;
$green: #24c289;
$orange: #ed8f21;
$orange: orange;
$white: #ffffff;
$black: #000000;

@function darkenOnHover($color, $percentage: 15%) {
  @return darken($color, $percentage);
}
