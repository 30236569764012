@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.pigeon {
    padding: 187px 0;
    position: relative;

    @media screen and (max-width: 1350px) {
        padding: 120px 0;
    }

    @media screen and (max-width: 1024px) {
        padding: 100px 0;
    }

    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 514px) {}



    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .top {
            color: $button-color;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }

        h2 {
            font-size: 40px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .basicText {
            width: 70%;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        .img2,
        .img1 {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            
            width: 100%;

            
        }

        .img1 {
          
            margin-top: 60px;
            background-image: url('./images/imgPigeon.png');
            height: 477px;
            @media screen and (max-width: 768px) {
                margin-top: 20px;
                background-image: url('./images/bgPigoneMobile.png');
                height: 350px;
            }

            
            @media screen and (max-width: 514px) {
                height: 280px;
            }

        }

        .img2 {
            height: 400px;
            background-image: url('./images/img2.png');

            @media screen and (max-width: 768px) {
               height: 300px;
            }

            @media screen and (max-width: 514px) {
                height: 150px;
            }
        }
    }
}