@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.headerTech {
    height: 100vh;
    padding: 82px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('./images/techHead.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media screen and (max-width: 1350px) {
        padding: 120px 0;
    }

    @media screen and (max-width: 1024px) {
        padding: 100px 0;
    }

    @media screen and (max-width: 768px) {
        display: block;
        padding: 150px 0;
    }

    @media screen and (max-width: 514px) {}

    .bar {
        width: 120px;
        height: 1000px;
        border-radius: 140px;
        position: absolute;
        transform: rotate(-120deg);

        @media screen and (max-width: 768px) {
            width: 0;
        }
      }
    
      .bar.top {
        background: linear-gradient(180deg, #00f957 0.75%, rgba(0, 249, 86, 0.01) 100%);
        top: 250px;
        right: 40px;
      }
    
      .bar.bottom {
        background: linear-gradient(180deg, #00f957.75%, rgba(0, 249, 86, 0.01)100%);
        top: 220px;
        right: 320px;
      }

    // .right {
    //     position: absolute;
    //     background-image: url('./images/technologyBg.png');
    //     width: 80%;
    //     height: 100%;
    //     top: 100px;
    //     right: -60px;
    //     background-position: right;
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     z-index: 1;

    //     @media screen and (max-width: 514px) {

    //         width: 120%;
    //         height: 110%;
    //         top: 280px;
    //         left: -45px;

    //         background-position: center center;
    //     }
    // }

    .headerText {
        @media screen and (max-width: 768px) {
            position: relative;
        }
    }

    a {
        width: fit-content;
    }




}