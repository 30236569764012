.product {
    padding: 0px 0 100px 0;
    position: relative;

    .orbRow {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        flex-direction: row;
        margin-bottom: 20px;
        margin-top: -50px;
    }

    .orb {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: 3px solid #00F956;
        width: 100px;
        height: 100px;

        p {
            font-size: 11px;
            color: #00F956;
            font-weight: 700;
        }
    }

    .wrapperImg {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        

        

        .img {
            margin-top: 40px;
            background-image: url('./images/tijdelijk\ 2.png');
            position: relative;
            width: 750px;
            height: 360px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left;
            margin-bottom: 50px;

            .all {
                display: flex;
                align-items: center;
                position: absolute;
                opacity: 0;
                transition: opacity 0.5s ease-in;
                background-color: transparent;

                &.visible {
                    opacity: 1;
                }



                .rectBig,
                .rectSmall,
                .rectMed {
                    height: 3px;
                    background-color: #00F956;
                }

                .rectBig {
                    width: 150px;
                }

                .rectSmall {
                    width: 50px;
                }

                .rectMed {
                    width: 90px;
                }
            }
        }
    }

    .swiper {
        padding: 30px 0;

        .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            width: 80px;
            height: 80px;
            border-radius: 100%;
            background-color: #459A57;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .swiper-slide-active {
            transform: scale(1.2);
            border: 2px solid #00F956;

            @media screen and (max-width: 514px) {
                margin-left: 6px;
            }
        }

        .swiper-slide-next {
            @media screen and (max-width: 514px) {
                margin-left: 6px;
            }
        }
    }

    .content {
        text-align: center;

        .textTitle {
            color: #00F956 !important;
            margin-top: -40px;

            @media screen and (max-width: 514px) {
                margin-left: -4px;
                margin-top: -40px;
            }
        }

        .desc {
            margin-top: 10px;
            min-height: 22px;

            @media screen and (max-width:768px) {
                min-height: 45px;
                margin-bottom: 10px;
                font-size: 14px;
            }
        }
    }
    
    .bottomContent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 20px;

       
    }
}