@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.map {



    .text {
        color: $primary-color;

        h4 {
            font-size: 20px;
        }
    }


}

.gm-style-iw, .gm-style-iw-c {
    padding-top: 12px !important;
    top: -25px !important;
    
}

.gm-style-iw-chr {
    display: block;
}

.gm-style-iw-ch {
    padding-top: 0px;
}

.gm-style-iw-chr {
    max-height: 0px;
    visibility: hidden;
    button {
        width: 0px;
        height: 0px;
    }
}

.gm-style-iw-tc {
    top: -25px !important;
}

