@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.figures {
    position: absolute;
}

.circle {
    width: 260px;
    height: 260px;
    border-radius: 260px;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.08) 24.01%, rgba(53, 6, 140, 0.01) 71.35%);
}

.tube {
    fill: linear-gradient(120deg, rgba(255, 255, 255, 0.08) 26.97%, rgba(255, 255, 255, 0.00) 71.35%);
    width: 240px;
    height: 240px;
}


.stripe {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 34.75%, rgba(255, 255, 255, 0.10) 100%);
    width: 120px;
    height: 997.881px;
    transform: rotate(60deg);
    flex-shrink: 0;
    border-radius: 120px;
}