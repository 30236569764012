@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.paintPallet {
    position: relative;
    width: 425px;
    height: 200px;
    overflow: hidden;

    @media screen and (max-width: 514px) {
       width: 315px;
    }
}

.draggableRectangle {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    z-index: 2;

    .iconCenter {
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: $button-color;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc(50% - 40px);
        right: calc(50% - 25px);
    }
}

.leftSide,
.rightSide {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 514px) {
        background-size: contain;
    }
}

.leftSide {
    
    left: 0;
    background-image: url('./images/Assembled2.png');
    clip-path: inset(0 50% 0 0); // Initially cover half of the left side
    z-index: 1;
}

.rightSide {
    
    right: 0;
    background-image: url('./images/Explode12.png');
    clip-path: inset(0 0 0 50%); // Initially cover half of the right side
    z-index: 0;
}
