@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.mesh {

    .content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        row-gap: 15px;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            flex-wrap: nowrap;
        }

        .kaart {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 453.5px;
            height: 205px;
            padding: 10px 52px 10px 20px;
            align-items: flex-start;
            gap: 21px;
            flex-shrink: 0;
            border-radius: 18px;
            border: 1px solid rgba(255, 255, 255, 0.05);
            background-color: rgba(255, 255, 255, 0.02);

            @media screen and (max-width: 1310px) {
                width: 49%;
                padding: 10px 20px 10px 20px;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 10px 20px 10px 20px;
            }

            h4 {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                color: $button-color;

            }

            p {
                color: $white;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .img {
            top: -110px;
            left: calc(50% - 260px);
            width: 500px;
            height: 620px;
            background-image: url('./images/meshBG.png');
            position: absolute;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;

            @media screen and (max-width: 1487px) {
                width: 400px;
                height: 500px;
                left: calc(50% - 200px);
                top: -70px;
            }


            @media screen and (max-width: 1310px) {
                position: relative;
                top: auto;
                left: auto;
                width: 100%;
                height: 534px;

            }

            @media screen and (max-width: 514px) {

                height: 350px;

            }
        }
    }
}