@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.slider {
    display: flex;
    gap: 15px;
    width: fit-content;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    justify-content: space-between;
    padding: 4px;
    border-radius: 32px;

    @media screen and (max-width: 950px) {
        width: 100%;
        gap: 5px;
    }

    button {
        min-width: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 15px;
        border-radius: 32px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        @media screen and (max-width: 950px) {
            min-width: 120px;
            padding: 2px 10px;
        }

        @media screen and (max-width: 514px) {
            min-width: 98px;
            padding: 2px 4px;
        }

        p {
            color: $white !important;
            font-weight: 600;

            @media screen and (max-width: 514px) {
                font-size: 11px !important;
            }
        }

        &:hover {
            opacity: 0.8;
        }
    }

    button.active {
        background-color: $button-color;
        color: white;

        p {
            color: $primary-color !important;

            @media screen and (max-width: 514px) {
                font-size: 11px !important;
            }
        }
    }


}