@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.headerManu {
    height: 100vh;
    padding: 82px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('./images/manubg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    

    @media screen and (max-width: 1350px) {
        padding: 120px 0;
    }

    @media screen and (max-width: 1024px) {
        padding: 100px 0;
    }

    @media screen and (max-width: 768px) {
        display: block;
        padding: 150px 0;
    }

    .content {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 950px) {
            flex-direction: column;
            row-gap: 30px;
        }

        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 35px;

            a {
                width: fit-content;
            }


            @media screen and (max-width: 950px) {
                row-gap: 10px;
            }

            button {
                width: fit-content;
            }

            .headerSub {
                width: 100%;
            }
        }

        .right {
            flex: 1;
            // background-image: url('./images/play.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;


            @media screen and (max-width: 950px) {
                flex: auto;
                width: 100%;
                height: 250px;
            }


            .stripe1,
            .stripe2 {
                width: 120px;
                height: 1000px;
                transform: rotate(-120deg);
                border-radius: 120px;
                position: absolute;


                @media screen and (max-width: 950px) {
                    width: 80px;
                    height: 900px;

                }
            }

            .stripe1 {
                top: -20px;
                left: -300px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.49%, #00F956 39.06%, rgba(255, 255, 255, 0.00) 99.92%);
            }

            .stripe2 {
                background: linear-gradient(180deg, #00F956 34.75%, rgba(0, 249, 86, 0.01) 100%);
                top: -200px;
                right: -50px;
            }
        }



    }

}