@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.headerAbout {
  height: 100vh;
  padding: 82px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('./images/about.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  

  @media screen and (max-width: 1350px) {
    padding: 120px 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 100px 0;
  }

  @media screen and (max-width: 768px) {
    display: block;
    padding: 150px 0;
  }

  .bar {
    width: 120px;
    height: 1000px;
    border-radius: 140px;
    position: absolute;
    transform: rotate(-120deg);
  }

  .bar.top {
    background: linear-gradient(180deg, #00f957 0.75%, rgba(0, 249, 86, 0.01) 100%);
    top: -160px;
    right: 40px;
  }

  .bar.bottom {
    background: linear-gradient(180deg, #00f957.75%, rgba(0, 249, 86, 0.01)100%);
    top: -100px;
    right: 480px;
  }

  a {
    width: fit-content;
  }

  // .right {
  //   position: absolute;
    
  //   width: 100%;
  //   height: 900px;
  //   top: -200px;
  //   right: -160px;
  //   background-position: right;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   z-index: 1;

  //   @media screen and (max-width: 514px) {
  //     width: 110%;
  //     left: 0px;
  //     top: -50px;
  //   }
  // }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 30px;

    @media screen and (max-width: 1444px) {
      row-gap: 25px;
    }

    @media screen and (max-width: 909px) {
      row-gap: 20px;
    }

    @media screen and (max-width: 768px) {
      row-gap: 15px;
    }

    @media screen and (max-width: 514px) {
      row-gap: 10px;
    }

    button {
      width: fit-content;
    }

  }


}