@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

@keyframes lavaLamp {
    0%, 100% {
        transform: translateY(0) rotate(-120deg);
        background-position: 0% 50%;
    }
    50% {
        transform: translateY(-20px) rotate(-120deg);
        background-position: 100% 50%;
    }
}

@keyframes lavaLamp2 {
    0%, 100% {
        transform: translateY(0) rotate(-120deg);
        background-position: 50% 0%;
    }
    50% {
        transform: translateY(20px) rotate(-120deg);
        background-position: 50% 100%;
    }
}

.john {
    background-color: $white;
    color: $primary-color;
    padding: 120px 0 150px 0;

    @media screen and (max-width: 950px) {
        padding: 90px 0 100px 0;
    }

    @media screen and (max-width: 514px) {
        padding: 45px 0 0px 0;
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;

        .boxke {
            width: 900px;
            position: relative;
            padding: 80px 0;

            .avatar {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                align-items: center;
                margin-top: 45px;

                .name {
                    text-transform: uppercase;
                    font-size: 19px;
                    font-weight: 800;
                    z-index: 5;
                }

                .imgA {
                    margin-bottom: 10px;
                    width: 80px;
                    height: 80px;
                    background-image: url('./images/avatar.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 5;
                }

                .info {
                    color: #6E6C83;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 32px;
                    z-index: 10000;
                }
            }

            .stripe1,
            .stripe2 {
                position: absolute;
                filter: blur(70px);
                width: 120px;
                position: absolute;
                
            }

            .stripe1 {
                animation: lavaLamp 5s infinite;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.49%, #00F956 39.06%, rgba(255, 255, 255, 0.00) 99.92%);
                border-radius: 140px;
                height: 1002.259px;
                top: -300px;
                left: 200px;
            }

            .stripe2 {
                animation: lavaLamp2 3s infinite;
                border-radius: 140px;
                background: linear-gradient(180deg, #00F956 34.75%, rgba(0, 249, 86, 0.01) 100%);
                height: 997.881px;
                top: -200px;
                right: 0px;
            }

            .quotes {
                position: absolute;
                top: 0;
                left: 0;
                width: 51px;
                height: 51px;
                background-image: url('./images/quotes.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            .desc {
                z-index: 1000;
                font-size: 20px;
                line-height: 200%;
                color: $primary-color;
                text-align: center;
                position: relative;

                span {
                    text-decoration: underline;
                }
            }
        }
    }
}
