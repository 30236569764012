@import '../_styles/colors';
@import '../_styles/buttons';
@import '../_styles/breakpoints';

.footer {
  background: linear-gradient(270deg, #223320 12.07%, #014917 100%);
  padding: 80px 0;

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      row-gap: 40px;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        align-items: center;
        row-gap: 20px;
      }

      .logo {
        background-image: url('./images/icon.png');
        width: 40px;
        height: 40px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

      }

      .button {

        p {
          color: #014917 !important;
        }
      }


    }



    .quickLinks {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      @media screen and (max-width: 768px) {
        align-items: center;
      }

      p {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;


      }

      a {
        text-decoration: none;
        color: $grey;
        font-size: 16px;
        display: flex;
        align-items: center;
        column-gap: 10px;

        p {
          margin-bottom: 0px;
        }

      }

      a.active {
        height: auto;
        color: $button-color;
      }

      div {
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }

    h4 {
      width: 15%;
      text-align: right;

      @media screen and (max-width: 7680px) {
        text-align: center;
        width: 30%;
      }
    }
  }
}