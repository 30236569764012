@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/breakpoints';

.reserved {
    background: rgba(217, 217, 217, 0.05);
    padding: 30px 0;

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
            row-gap: 20px;
          }
    }
}