@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.headerContact {
    height: 100vh;
    padding: 102px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(270deg, #223320 12.07%, #014917 100%);
    overflow: hidden; //terug verwijderen

    @media screen and (max-width: 1350px) {
        padding: 120px 0;
    }

    @media screen and (max-width: 1024px) {
        padding: 100px 0;
    }

    @media screen and (max-width: 768px) {
        display: block;
        padding: 150px 0 0px;
    }



    .right {
        position: absolute;
        background-image: url('./images/headerAboutPNG.png');
        width: 80%;
        height: 100%;
        top: 100px;
        right: -60px;
        background-position: right;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;

        @media screen and (max-width: 514px) {
            width: 120%;
            left: -20px;
            top: 50px;
        }
    }

    .content {
        z-index: 1000;

        a {
            max-width: fit-content;
        }

        .left {
            row-gap: 30px;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 1444px) {
                row-gap: 25px;
            }

            @media screen and (max-width: 909px) {
                row-gap: 20px;
            }

            @media screen and (max-width: 768px) {
                row-gap: 15px;
            }

            @media screen and (max-width: 514px) {
                row-gap: 10px;
            }

            .headerTitle {
                width: 50%;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }


        }
    }


}