@font-face {
    font-family: 'Eudoxus';
    src: url('./../_assets/css/font-files/EudoxusSans-ExtraLight.woff') format('woff'),
    url('./../_assets/css/font-files/EudoxusSans-ExtraLight.woff2') format('woff2');
    font-weight: lighter;
    font-weight: 200;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('./../_assets/css/font-files/EudoxusSans-Light.woff') format('woff'),
    url('./../_assets/css/font-files/EudoxusSans-Light.woff2') format('woff2');
    font-weight: light;
    font-weight: 300;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('./../_assets/css/font-files/EudoxusSans-Regular.woff') format('woff'),
    url('./../_assets/css/font-files/EudoxusSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('./../_assets/css/font-files/EudoxusSans-Medium.woff') format('woff'),
    url('./../_assets/css/font-files/EudoxusSans-Medium.woff2') format('woff2');
    font-weight: medium;
    font-weight: 500;
}



@font-face {
    font-family: 'Eudoxus';
    src: url('./../_assets/css/font-files/EudoxusSans-Bold.woff') format('woff'),
    url('./../_assets/css/font-files/EudoxusSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-weight: 700;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('./../_assets/css/font-files/EudoxusSans-ExtraBold.woff') format('woff'),
    url('./../_assets/css/font-files/EudoxusSans-ExtraBold.woff2') format('woff2');
    font-weight: extrabold;
    font-weight: 800;
}
$font-size: 12px;
$font-family: 'Eudoxus', sans-serif;

$border-radius:32px ;
$disabled-opacity: 0.75;
$navbar-height: 10rem;
$input-height: 4rem;

$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;


