@import './_styles/breakpoints';
@import './_styles/colors';
@import './_styles/constants';
@import './_styles/form';
@import './_styles/reset';

html {
  // This value defines the value of '1rem'
  font-size: 10px;
}

html,
body {
  height: auto;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  background-color: $primary-color;
}

body.no-scroll {
  overflow: hidden;
}

h1 {
  font-size: 64px;
  font-weight: 600;
  line-height: 1;
  width: 70%;

  @media screen and (max-width: 1444px) {
    font-size: 44px;
  }

  @media screen and (max-width: 909px) {
    width: 100%;
    font-size: 38px;
  }

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }

  @media screen and (max-width: 514px) {

    font-size: 26px;
  }
}

h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;

  @media screen and (max-width: 1444px) {
    font-size: 40px;
  }

  @media screen and (max-width: 909px) {
    
    font-size: 32px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }

  @media screen and (max-width: 514px) {

    font-size: 22px;
  }
}

h3 {
  font-weight: 600;
  font-size: 48px;

  @media screen and (max-width: 1200px) {
    font-size: 43px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 38px;
  }

  @media screen and (max-width: 768px) {
    font-size: 33px;
  }

  @media screen and (max-width: 514px) {
    font-size: 27px;
  }
}

h4 {
  font-size: 32px;
  font-weight: 600;

  @media screen and (max-width: 1200px) {
    font-size: 28px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 514px) {
    font-size: 18px;
  }
}

body {
  font-size: $font-size;
}

a {
  text-decoration: none;

  &.disabled {
    pointer-events: none;
    opacity: $disabled-opacity;
  }
}

.button {
  background-color: $button-color;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px;
  min-width: 150px;
  cursor: pointer;
  column-gap: 10px;
  z-index: 10000;

  @media screen and (max-width: 950px) {
    min-width: 120px;
  }

  @media screen and (max-width: 768px) {
    min-width: 100px;
  }

  @media screen and (max-width: 514px) {
    min-width: 80px;
  }


  &:hover {
    opacity: 0.9;

    p {}
  }

  p {
    color: $primary-color;
    font-weight: 600;
    font-size: 18px;
    line-height: normal;

    @media screen and (max-width: 950px) {
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    @media screen and (max-width: 514px) {
      font-size: 12px;
    }

  }

  i.icon svg path {
    fill: $white;
  }

  .circle {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: $primary-color;
    margin-left: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .inside {
      background-image: url('./_assets/svg/svgChevronRight.svg');
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }
}


.headerText {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  z-index: 1000;

  @media screen and (max-width: 1444px) {
    row-gap: 25px;
  }

  @media screen and (max-width: 909px) {
    row-gap: 20px;
  }

  @media screen and (max-width: 768px) {
    row-gap: 15px;
  }

  @media screen and (max-width: 514px) {
    row-gap: 10px;
  }

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    @media screen and (max-width: 1444px) {
      row-gap: 25px;
    }
  
    @media screen and (max-width: 909px) {
      row-gap: 20px;
    }
  
    @media screen and (max-width: 768px) {
      row-gap: 15px;
    }
  
    @media screen and (max-width: 514px) {
      row-gap: 10px;
    }
  }

  button {
    width: fit-content;
  }
}

.desc {
  color: $grey;
  font-size: 16px;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .margins,
  .marginsSmall,
  .marginsMini,
  .marginsSwiper {
    width: 100%;
    padding: 30px 100px;
    margin: auto;


    @media screen and (max-width: 768px) {
      padding: 30px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }

    .basicText {
      font-size: 18px;
      line-height: 200%;
      color: $grey;

      @media screen and (max-width: 1400px) {
        font-size: 14px;
      }

      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }

    .headerSub {
      color: $white;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.24px;
      width: 60%;
      z-index: 1000;

      @media screen and (max-width: 1444px) {
        font-size: 20px;
      }

      @media screen and (max-width: 909px) {
        width: 100%;
        font-size: 18px;
      }

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }

      @media screen and (max-width: 514px) {

        font-size: 14px;
      }
    }

    .headerTitle {
      font-size: 64px;
      font-weight: 600;
      line-height: 1;
      width: 70%;
      z-index: 1000;

      @media screen and (max-width: 1444px) {
        font-size: 44px;
      }

      @media screen and (max-width: 909px) {
        width: 100%;
        font-size: 38px;
      }

      @media screen and (max-width: 768px) {
        font-size: 30px;
      }

      @media screen and (max-width: 514px) {

        font-size: 26px;
      }
    }

    .chain {
      width: 160px;
      height: 35px;
      background-color: $primary-color;
      border-radius: 50px;
      border:none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;

      p {
        color: $button-color;
      }
    }


  }

  .margins {
    max-width: 1380px;

  }

  .marginsSmall {
    max-width: 1494px;
  }

  .marginsMini {
    max-width: 1494px;
  }

  .marginsSwiper {
    max-width: 1200px;
  }
}