@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.module {
    background-color: $white;
    padding: 65px 0 105px 0;

    @media screen and (max-width: 1350px) {
        
    }

    @media screen and (max-width: 1024px) {
       
    }

    @media screen and (max-width: 768px) {
       
    }

    @media screen and (max-width: 514px) {
        
    }

    .content {

        h2 {
            color: $primary-color;
            margin-bottom: 15px;
            font-size: 40px;
        }

        .middleText {
            width: 100%;
            display: flex;
            justify-content: space-between;
            column-gap: 40px;

            
        
            @media screen and (max-width: 768px) {
               flex-direction: column;
               row-gap: 20px;
            }
        
            

            .text {
                flex: 1;
                color: $primary-color;
                font-size: 16px;
                line-height: 200%;
                text-align: justify;

                @media screen and (max-width: 768px) {
                    text-align: left;
                 }
            }
        }

        .boxRow {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            justify-content: center;
            margin-top: 88px;

            @media screen and (max-width: 768px) {
                margin-top: 45px;
             }
         

            .boxCard {
                padding: 20px 114px 20px 60px;
                position: relative;
                border-radius: 12px;
                background-color: $primary-color;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                border: 1px solid rgba(255, 255, 255, 0.08);
                width: 582px;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding: 20px 70px 20px 30px;
                 }

                h4 {
                    margin-bottom: 10px;
                    font-size: 24px;
                    color: $button-color;
                }

                .basicText {
                    min-height: 144px;
                    text-align: justify;

                   

                    @media screen and (max-width: 768px) {
                        text-align: left;
                        min-height: 96px;
                     }

                     @media screen and (max-width: 393px) {
                        min-height: 144px;
                    }
                }

                .icon {
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 64px;

                    @media screen and (max-width: 768px) {
                        right: 25px;
                    }
                }
            }
        }
    }
}