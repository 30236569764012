@import './../../_styles/breakpoints';
@import './../../_styles/buttons';
@import './../../_styles/colors';
@import './../../_styles/constants';
@import './../../_styles/form';

.people {
    padding: 110px 0 0px 0;
    position: relative;

    @media screen and (max-width: 950px) {
        padding: 90px 0 90px 0;
    }

    @media screen and (max-width: 514px) {
        padding: 70px 0 70px 0;
    }

    .swiper {
        width: 100%;
        height: 100%;
        overflow: visible;
        padding: 70px;

        @media screen and (max-width: 768px) {
            padding: 35px;
        }

        @media screen and (max-width: 515px) {
            padding: 15px;
        }

        .swiper-slide {
            transition: all .3s ease;
            width: 90%;
            opacity: 0.3;


            &.swiper-slide-active {
                transform: scale(1.10);
                z-index: 10000;
                opacity: 1;
            }
        }

        .swiper-slide,
        .swiper-slide-next,
        .swiper-slide-previous,
        .swiper-slide-active {
            width: 500px;
        }

        .swiper-pagination {
            bottom: -50px;

        }

        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            opacity: 1;
            background: rgba(0, 0, 0, 0.2);

            &.swiper-pagination-bullet-active {
                background: #FFFFFF;
                width: 8px;
                height: 8px;
            }
        }
    }


    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title,
        .title2 {
            margin-top: 10px !important;
            margin-bottom: 10px !important;
            max-width: 772px;
            display: flex;
            align-items: flex-start;
        }

        .title2 {
            margin-top: 60px !important;
        }

        .box .left .basicText {
            text-align: justify;
            margin-bottom: 0px;
        }

        .basicText {
            text-align: center;
            margin-bottom: 85px;
        }

        h4 {
            margin-bottom: 44px;

            span {
                color: $button-color;
            }

            @media screen and (max-width:514px) {
                font-size: 16px;
                text-align: center;
            }
        }

        .ava {
            width: 200px;
            height: 200px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }

        .text {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            width: 100%;
        }

        .name {
            padding: 0px 6px;
            border-radius: 4px;
            background: rgba(255, 255, 255, 0.07);
            width: fit-content;
        }



        .desc {
            font-size: 15px;
            color: #B6B6B6;
        }

        .lowerCard {
            width: 248px;
            border-radius: 18px;
            border: 1px solid rgba(255, 255, 255, 0.05);
            background: rgba(255, 255, 255, 0.05);
            backdrop-filter: blur(15px);
            padding: 20px 20px 20px 20px;
            row-gap: 15px;
            display: flex;
            flex-direction: column;

            .around {
                width: 100%;
                background-color: transparent;
                display: flex;
                justify-content: end;

                button {
                    background-color: transparent;
                    i.icon svg {
                        stroke: $white;
                    }

                    .up,
                    .down {

                        background-color: transparent;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        transition: transform 0.6s ease;



                    }

                    .up {
                        transform: rotate(-180deg);
                    }
                }


            }



            @media screen and (max-width: 595px) {
                width: 100%;
            }

        }

        .upper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            column-gap: 20px;
            justify-content: center;
            margin-bottom: 32px;
            align-items: center;


            .upperCard {
                display: flex;
                width: 376px;
                padding: 20px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 15px;
                border-radius: 18px;
                border: 1px solid rgba(255, 255, 255, 0.05);
                background: rgba(255, 255, 255, 0.05);

                .role {
                    min-height: 34px;
                }

                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }
        }

        .lower {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 12px;
            justify-content: center;
            max-width: 772px;


            @media screen and (max-width: 971px) {
                grid-template-columns: repeat(2, 1fr);
                width: fit-content;
            }



            .test {
                background-color: $white;
                padding: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $black;
            }


        }

    }

}